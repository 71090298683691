<script>
export default {
  name: 'CheckboxItem',
  components: {
    Icon: () => import('@/components/general/Icon')
  },
  props: {
    switchType: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: null
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    labelWarning: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: null
    },
    value: null,
    index: {
      type: Number,
      default: null
    },
    checked: {
      type: Boolean,
      default: false
    },
    unique: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFocused: false,
      isChecked: this.checked
    }
  },
  watch: {
    checked (value) {
      this.isChecked = value
    }
  },
  methods: {
    updateValue (item) {
      this.isChecked = item.checked
      this.$emit('change', { value: item.value, checked: item.checked, unique: this.unique })
    },
    updateFocus () {
      this.isFocused = true
    },
    updateBlur () {
      this.isFocused = false
    },
    checkLinkInside (event) {
      event.target.nodeName === 'A' && this.$emit('label-link')
    }
  }
}
</script>

<template>
  <div :class="{ 'form-checkbox-switch-item': switchType, 'form-checkbox-item': !switchType, 'is-disabled': disabled, 'is-checked': isChecked, 'has-focus': isFocused, 'has-only-icon': !label }">
    <input
      :id="'checkbox-' + _uid + '-' + index"
      :class="'form-checkbox checkbox-' + index "
      type="checkbox"
      :name="'checkbox-' + _uid"
      :disabled="disabled"
      :readonly="readonly"
      :checked="isChecked"
      :value="value"
      @focus="updateFocus()"
      @blur="updateBlur()"
      @change="updateValue($event.target)"
    >
    <icon
      v-if="isChecked && !switchType"
      name="check_box"
    />
    <icon
      v-if="!isChecked && !switchType"
      name="check_box_outline_blank"
    />
    <label
      class="form-checkbox-label"
      :for="'checkbox-' + _uid + '-' + index"
      :class="{'align-right': alignRight, 'has-text': label}"
      @click="checkLinkInside($event)"
    >
      <span
        v-if="switchType"
        class="switch"
      />
      <span v-html="label" />
      <p
        v-if="description"
        class="description"
      >{{ description }}</p>
    </label>
    <p
      v-if="labelWarning"
      class="form-checkbox-warning"
      v-html="labelWarning"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>
